export function setCookie({
  name,
  value,
  path = '/',
  domain = window.location.hostname,
  daysToExpire = 365,
}: {
  name: string;
  value: string;
  path?: string;
  domain?: string;
  daysToExpire?: number;
}) {
  const date = new Date();
  date.setTime(date.getTime() + daysToExpire * 24 * 60 * 60 * 1000);
  const expires = date.toUTCString();

  document.cookie = `${name}=${value}; path=${path}; domain=${domain}; expires=${expires}`;
}
