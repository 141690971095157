import { gtm } from '../scripts/gtm';
import { getLocaleFromPath } from '../util/locales';
import { config } from '../../config';
import { getSparrowUserId } from './sparrow';
import { getCookie } from '../util/getCookie';

export const DEFAULT_EVENT_CATEGORY = 'Form';

export function trackFormSubmit(leadSource: string, formState: string, ctaText: string) {
  const label = `(${formState}) ${leadSource}`;
  gtm({
    event: 'FormSubmission',
    eventCategory: DEFAULT_EVENT_CATEGORY,
    eventAction: label,
    eventLabel: ctaText,
  });
}

/**
 * Returns data from the ga global object
 *
 * @param {String} fieldName
 */
export function getGAtrackingField(fieldName: string) {
  // MRK-8034 - "Hardcode" this Client ID for testing
  if (fieldName === 'trackingId') {
    return config.google_analytics_ua_code_www_marketing_29;
  }
  if (!(window as any).ga || !(window as any).ga.getAll) {
    return '';
  }
  const tracker = (window as any).ga.getAll()[0];

  if (!tracker) return '';

  return tracker.get(fieldName) || '';
}

// Tracks the i18n hyphenated language
export function trackI18nData() {
  let locale = getLocaleFromPath(window.location.pathname);

  gtm({
    DLV_InternationalDomain: locale.toLowerCase(),
    // TODO: Figure what to actually put here
    Localized_Content: true,
  });
}

export function trackSparrowUserId() {
  let sparrowId = getSparrowUserId();

  if (sparrowId) {
    gtm({ user_id: sparrowId });
  }
}

// Tracks http status, this assumes any page without the "error-page-content" blade is a 200
export function trackHttpStatus() {
  const isErrorPage = !!document.getElementById('error-page-content');
  const status = isErrorPage ? 400 : 200;
  gtm({ HTTP_Status: status });
}

// Tracks if page's robots meta tag is set to nofollow
export function trackIndexStatus() {
  const robotsMetaTag = document.querySelector("meta[name='robots']");

  if (!robotsMetaTag) return gtm({ Index_Status: 'Indexable' });

  const content = robotsMetaTag.getAttribute('content');

  if (!content) return gtm({ Index_Status: 'Indexable' });

  const followStatus = content.indexOf('noindex') > -1 ? 'Blocked' : 'Indexable';

  gtm({ Index_Status: followStatus });
}

/**
 * MRK-8166 - This parses the data in the "_gaexp" cookie, which holds information about any
 * google optimize experiments that the user has seen in that browser.
 */
export function getOptimizeExperiments() {
  const gaExp = getCookie('_gaexp');

  if (gaExp) {
    return gaExp
      .replace(/GAX[0-9].[0-9]./, '')
      .split('!')
      .map(function(exp) {
        const [expId, ...split] = exp.split('.');
        const variant = split[split.length - 1];
        return { experimentId: expId, variant: variant };
      });
  }

  return [];
}
