import { MaybeRedwood } from '../../config';
import { objectKeys } from '../util/object';

export const AllConsentGroupsEnabled = {
  C0001: true,
  C0002: true,
  C0003: true,
  C0004: true,
};

export const AllConsentGroupsDisabled = {
  C0001: false,
  C0002: false,
  C0003: false,
  C0004: false,
};

export interface ConsentGroups {
  C0001: boolean;
  C0002: boolean;
  C0003: boolean;
  C0004: boolean;
}

export function getConsentGroups() {
  // The redwood namespace and consent groups will be populated by our
  // root worker if the user has consented.
  const redwood = (window as MaybeRedwood).redwood;

  if (redwood) return redwood.consentGroups;

  return AllConsentGroupsDisabled;
}

const libraryMappings = {
  cfmrk_eucookiebanner: 'C0001',
  techTarget: 'C0002',
  marketo: 'C0002',
  bizible: 'C0002',
  heap: 'C0002',
  ga: 'C0002',
  cfmrk_cic: 'C0003',
  cfmrk_userLangRedirect: 'C0003',
  gtm: 'C0004',
  sparrow: 'C0002',
  optimize: 'C0002',
} as const;

type Libraries = keyof typeof libraryMappings;
type AllowedLibraries = { [K in Libraries]: boolean };

export function getAllowedLibraries(consentGroups: ConsentGroups): AllowedLibraries {
  return objectKeys(libraryMappings).reduce<AllowedLibraries>(
    (result, key) => Object.assign(result, { [key]: consentGroups[libraryMappings[key]] }),
    {} as any
  );
}
