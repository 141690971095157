export function gtm(data: any) {
  if (Array.isArray((window as any).dataLayer)) {
    (window as any).dataLayer.push(data);
    return;
  }
}

let didLoad = false;

export function loadGoogleTagManager() {
  if (didLoad) return;

  const script = document.createElement('script');
  script.src = 'https://tr.www.cloudflare.com/gtm.js?id=GTM-PKQFGQB';

  script.async = true;

  (window as any).dataLayer = (window as any).dataLayer || [];

  document.head.appendChild(script);

  const iframe = document.createElement('iframe');
  const noscript = document.createElement('noscript');

  iframe.src = '//tr.www.cloudflare.com/ns.html?id=GTM-PKQFGQB';

  (iframe as any).height = 0;
  (iframe as any).width = 0;
  iframe.style.display = 'none';
  iframe.style.visibility = 'hidden';
  noscript.appendChild(iframe);
  document.head.appendChild(noscript);

  didLoad = true;
}
