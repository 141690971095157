export type RVSResult = { rv1: any; rv2: any };

// get user personalization score - MRK-10457
// https://bitbucket.cfdata.org/projects/MAR/repos/workers/browse/www-cloudflare-com

export async function rvs(url = '') {
  const res = await fetch(`https://www.cloudflare.com/rvs/?u=${encodeURIComponent(url)}`);
  const result = await res.json();

  assertRVSResult(result);

  return result;
}

function assertRVSResult(result: any): asserts result is RVSResult {
  if (!result || !result.rv1 || !result.rv2) {
    console.error('Invalid RVS value. rv1 or rv2 missing', result);
    throw new Error('Invalid RVS value. rv1 or rv2 missing');
  }

  return result;
}
