/**
 * Generates the typings for usage with Object.keys
 *
 *  @example
 *  const Locations = { "Cina": "Austin", "John": "Austin", "Kevin": "Austin", "Nicky": "San Francisco", "Robert": "San Francisco" }
 *  const KeysFromLocations = objectKeys(Locations)
 */
export function objectKeys<T>(t: T) {
  return Object.keys(t) as (keyof T)[];
}
