// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-about-your-website-about-your-website-tsx": () => import("./../../../src/components/about-your-website/about-your-website.tsx" /* webpackChunkName: "component---src-components-about-your-website-about-your-website-tsx" */),
  "component---src-components-about-your-website-recommendation-recommendation-tsx": () => import("./../../../src/components/about-your-website/recommendation/recommendation.tsx" /* webpackChunkName: "component---src-components-about-your-website-recommendation-recommendation-tsx" */),
  "component---src-components-case-studies-templates-case-studies-template-tsx": () => import("./../../../src/components/case-studies/templates/case-studies.template.tsx" /* webpackChunkName: "component---src-components-case-studies-templates-case-studies-template-tsx" */),
  "component---src-components-case-studies-templates-case-study-template-tsx": () => import("./../../../src/components/case-studies/templates/case-study.template.tsx" /* webpackChunkName: "component---src-components-case-studies-templates-case-study-template-tsx" */),
  "component---src-components-home-home-tsx": () => import("./../../../src/components/home/home.tsx" /* webpackChunkName: "component---src-components-home-home-tsx" */),
  "component---src-components-learning-center-templates-learning-center-article-template-tsx": () => import("./../../../src/components/learning-center/templates/learning-center-article.template.tsx" /* webpackChunkName: "component---src-components-learning-center-templates-learning-center-article-template-tsx" */),
  "component---src-components-page-page-template-tsx": () => import("./../../../src/components/page/page.template.tsx" /* webpackChunkName: "component---src-components-page-page-template-tsx" */),
  "component---src-components-people-people-tsx": () => import("./../../../src/components/people/people.tsx" /* webpackChunkName: "component---src-components-people-people-tsx" */),
  "component---src-components-plans-plans-template-tsx": () => import("./../../../src/components/plans/plans.template.tsx" /* webpackChunkName: "component---src-components-plans-plans-template-tsx" */),
  "component---src-components-press-press-tsx": () => import("./../../../src/components/press/press.tsx" /* webpackChunkName: "component---src-components-press-press-tsx" */),
  "component---src-components-press-releases-templates-press-release-template-tsx": () => import("./../../../src/components/press-releases/templates/press-release.template.tsx" /* webpackChunkName: "component---src-components-press-releases-templates-press-release-template-tsx" */),
  "component---src-components-press-releases-templates-press-releases-template-tsx": () => import("./../../../src/components/press-releases/templates/press-releases.template.tsx" /* webpackChunkName: "component---src-components-press-releases-templates-press-releases-template-tsx" */),
  "component---src-components-resource-hub-resource-hub-tsx": () => import("./../../../src/components/resource-hub/resource-hub.tsx" /* webpackChunkName: "component---src-components-resource-hub-resource-hub-tsx" */),
  "component---src-components-security-spotlight-security-spotlight-tsx": () => import("./../../../src/components/security-spotlight/security-spotlight.tsx" /* webpackChunkName: "component---src-components-security-spotlight-security-spotlight-tsx" */),
  "component---src-components-welcome-center-activation-tsx": () => import("./../../../src/components/welcome-center/activation.tsx" /* webpackChunkName: "component---src-components-welcome-center-activation-tsx" */),
  "component---src-components-welcome-center-core-setup-tsx": () => import("./../../../src/components/welcome-center/core-setup.tsx" /* webpackChunkName: "component---src-components-welcome-center-core-setup-tsx" */),
  "component---src-components-welcome-center-health-check-tsx": () => import("./../../../src/components/welcome-center/health-check.tsx" /* webpackChunkName: "component---src-components-welcome-center-health-check-tsx" */),
  "component---src-components-welcome-center-index-tsx": () => import("./../../../src/components/welcome-center/index.tsx" /* webpackChunkName: "component---src-components-welcome-center-index-tsx" */),
  "component---src-pages-button-page-tsx": () => import("./../../../src/pages/button-page.tsx" /* webpackChunkName: "component---src-pages-button-page-tsx" */)
}

