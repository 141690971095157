const path = require(`path`);

export const _locales = [
  'en-US',
  'de-DE',
  'ja-JP',
  'fr-FR',
  'en-GB',
  'en-CA',
  'en-AU',
  'en-IN',
  'es-ES',
  'it-IT',
  'ko-KR',
  'pt-BR',
  'zh-CN',
  'zh-TW',
  'es-LA',
  'nl-NL',
  'id-ID',
  'th-TH',
  'ru-RU',
  'sv-SE',
  'vi-VN',
] as const;

export type Locale = typeof _locales[number];

export function getLocaleSubset<LocaleSubset extends Locale[]>(subset: LocaleSubset): LocaleSubset {
  return subset;
}

export const euLocales = getLocaleSubset([
  'nl-NL',
  'en-GB',
  'fr-FR',
  'de-DE',
  'it-IT',
  'pt-BR',
  'es-ES',
  'es-LA',
  'sv-SE',
]);

export const asianLocales = getLocaleSubset(['ja-JP', 'ko-KR', 'zh-CN', 'zh-TW']);

export type EULocale = typeof euLocales[number];
export type AsianLocale = typeof asianLocales[number];

export function isEULocale(locale: Locale): locale is EULocale {
  return (euLocales as Locale[]).includes(locale);
}

export function isAsianLocale(locale: Locale): locale is AsianLocale {
  return (asianLocales as Locale[]).includes(locale);
}

export const shortLocaleMap = _locales.reduce((prev, curr) => {
  return {
    ...prev,
    [curr.split('-').join('')]: curr,
  };
}, {}) as { [key: string]: Locale };

const LOCALE = process.env.LOCALE;
let specificLocales: Locale[] = [];
if (
  typeof LOCALE === 'string' &&
  LOCALE !== '' &&
  !!_locales.find(l => l.toLowerCase() === LOCALE)
) {
  specificLocales = [getLocaleAsUpperCase(LOCALE) as Locale];
} else if (process.env.ENGLISH_ONLY === 'true') {
  specificLocales = _locales.slice(0, 2);
} else {
  specificLocales = [..._locales];
}
export const locales = [...specificLocales] as const;

export const LocaleMissing = 'LocaleMissing';
export type LocaleMissing = typeof LocaleMissing;

export const defaultLocale: Locale = 'en-US';

export function isValidLocale(maybeLocale: any): maybeLocale is Locale {
  return _locales.includes(maybeLocale);
}

function getByTag(maybeLocale: string): Locale {
  const localeIndex = _locales.map(locale => locale.toLowerCase()).indexOf(maybeLocale);

  if (localeIndex === -1) {
    return defaultLocale;
  }

  return _locales[localeIndex];
}

export function getLocaleFromPath(path: string): Locale {
  const [maybeLocale] = path.split('/').filter(component => !!component);

  if (typeof maybeLocale !== 'string') return defaultLocale;

  return getByTag(maybeLocale);
}

export function stripLocale(url: string) {
  const exists = _locales.find(l => url.indexOf(l.toLocaleLowerCase()) >= 0);
  if (exists) {
    return path.resolve(url.replace(exists.toLocaleLowerCase(), ''));
  }
  return url;
}

export function getResolvedLocalePath(locale: Locale, pagePath: string) {
  if (locale === defaultLocale) {
    return path.resolve(`/${pagePath}/`);
  }
  return path.resolve(`/${locale.toLocaleLowerCase()}/${pagePath}/`);
}

export function getLocalesAsString(joinBy: string) {
  return _locales.map(locale => locale.toLowerCase()).join(joinBy);
}

export function getLocaleAsUpperCase(str: string) {
  try {
    if (str.indexOf('-') < 0) {
      console.warn('getLocaleAsUpperCase::Invalid locale - ', str);
      return str;
    }
    const [p1, p2] = str.split('-');
    return `${p1}-${p2.toLocaleUpperCase()}`;
  } catch (e) {
    console.log('getLocaleAsUpperCase::error', e);
    return str;
  }
}
