import { parse } from 'cookie';

export const SPARROW_ID = 'sparrow_id';

/**
 * Represents Some value, or None
 */
export type Option<T> = T | undefined;

export type SparrowId = {
  userId?: string;
  deviceId: string;
};

/**
 * getCookie
 *
 * @param {string} cookieName - Name of the cookie to get
 * @returns {Object|undefined}  - Object if cookie exists
 *
 * Deserializes cookie
 */
export function getCookie<CookieValue = unknown>(cookieName: string) {
  let parsedCookie: Option<string> = parse(document.cookie)[cookieName];
  if (parsedCookie) {
    try {
      // Parse the string to object
      return JSON.parse(parsedCookie) as CookieValue;
    } catch (error) {
      console.log('Sparrow: Failed to parse cookie', error);
    }
  }
  return undefined;
}

export function getSparrowUserId(): Option<string> {
  const cookie: Option<SparrowId> = getCookie<SparrowId>(SPARROW_ID);
  return cookie?.userId;
}
