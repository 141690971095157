import * as gatsby from 'gatsby';
import { rvs } from './util/rvs';
import { getAllowedLibraries, getConsentGroups } from './scripts/onetrust';
import { MaybeRedwood } from '../config';
import {
  trackSparrowUserId,
  trackI18nData,
  trackHttpStatus,
  trackIndexStatus,
} from './analytics/trackers';
import { gtm, loadGoogleTagManager } from './scripts/gtm';
import { setCookie } from './util/setCookie';

/**
 * onRouteUpdate is called by Gatsby for each page view
 */
export async function onRouteUpdate({ location }: gatsby.RouteUpdateArgs) {
  const redwood = (window as MaybeRedwood).redwood;
  const consentGroups = getConsentGroups();
  const allowedLibraries = getAllowedLibraries(consentGroups);

  if (allowedLibraries.gtm) {
    loadGoogleTagManager();
    trackI18nData();
    trackHttpStatus();
    trackIndexStatus();
    // MRK-8033 - Add page grouping for all marketing site pages
    gtm({ PG_L1: 'Marketing Site' });
    gtm({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });

    if (redwood) {
      gtm({ colo: redwood.colo, location: redwood.country });
    }

    // TODO: Figure out if `excludeMarketo` and `marketoEnabled` are still relevant
    // if (!config('excludeMarketo') && config('marketoEnabled')) {
    // gtm({ event: 'MunchkinEnabled' });
    // }
  }

  if (allowedLibraries.sparrow && allowedLibraries.gtm) {
    trackSparrowUserId();
  }

  if (allowedLibraries.gtm || allowedLibraries.cfmrk_cic) {
    try {
      const { rv1, rv2 } = await rvs(location.href);

      // The MarketoForm component uses the value from rv1 in a hidden field.
      // We need to communicate that value somehow. Ideally, we'd use some sort
      // of centralized state instance, but a global will have to do for now
      if (redwood) {
        redwood.rv1 = rv1;
        redwood.rv2 = rv2;
      }

      if (allowedLibraries.gtm) {
        gtm({ rv1 });
        gtm({ rv2 });
      }

      if (allowedLibraries.cfmrk_cic) {
        setCookie({
          name: 'cfmrk_cic',
          value: JSON.stringify(rv2),
          domain: '.cloudflare.com',
          daysToExpire: 90,
        });
      }
    } catch (e) {
      console.error('Error fetching rvs', e);
    }
  }
}
